
import { Cropper, Point, Size, TransformParams } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'announcement-image-cropper',
  components: {
    Cropper,
  },
})
export default class AnnouncementImageCropper extends Vue {
  @Prop({ required: true })
  readonly src!: string;

  get stencilPropsSquare() {
    return {
      aspectRatio: 1,
    };
  }

  get stencilPropsHorizontal() {
    return {
      aspectRatio: 1.91,
    };
  }

  defaultSize({ imageSize, visibleArea }: TransformParams): Size {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    };
  }

  defaultPosition({ imageSize, coordinates }: TransformParams): Point {
    return {
      left: (imageSize.width - coordinates.width) / 2,
      top: (imageSize.height - coordinates.height) / 2,
    };
  }

  onChange(event: ReturnType<Cropper['getResult']>) {
    this.$emit('change', event);
  }
}
