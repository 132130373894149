
import AppFileDropzone from '@/components/ui/AppFileDropzone.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'image-dropzone',
  components: { AppFileDropzone },
})
export default class ImageDropzone extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly value!: string;
}
