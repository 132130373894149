
import AnnouncementImageCropper from '@/components/announcement/AnnouncementImageCropper.vue';
import ImageDropzone from '@/components/ui/ImageDropzone.vue';
import { Cropper } from 'vue-advanced-cropper';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'announcement-image-dropzone',
  components: { AnnouncementImageCropper, ImageDropzone },
})
export default class AnnouncementImageDropzone extends Vue {
  @Prop({ required: false, type: String })
  readonly id?: string;

  loadedImage: File | null = null;
  croppedImageSquare: File | null = null;
  croppedImageHorizontal: File | null = null;


  get imageSource(): string {
    return this.loadedImage ? URL.createObjectURL(this.loadedImage) : '';
  }

  onRemoveLoadedImage() {
    this.croppedImageSquare = null;
    this.croppedImageHorizontal = null;
    this.loadedImage = null;
  }

  onImageChange(event: ReturnType<Cropper['getResult']>) {
    if (!this.loadedImage) {
      return;
    }

    const { canvas: canvasSquare } = (this.$refs.cropper as any).$refs.cropperSquare.getResult();
    const { canvas: canvasHorizontal } = (this.$refs.cropper as any).$refs.cropperHorizontal.getResult();
    
    if (canvasSquare && canvasHorizontal) {
      canvasSquare.toBlob((blob: Blob) => {
        if (!this.loadedImage) return;

        const loadedImageSplit = this.loadedImage.name.split('.');
        const imageSquareName = loadedImageSplit[0] + '_square.' + loadedImageSplit[1];

        this.croppedImageSquare = new File([blob], imageSquareName, {
          type: this.loadedImage.type,
        });
      }, this.loadedImage.type);

      canvasHorizontal.toBlob((blob: Blob) => {
        if (!this.loadedImage) return;
        this.croppedImageHorizontal = new File([blob], this.loadedImage.name, {
          type: this.loadedImage.type,
        });
      }, this.loadedImage.type);

      this.$emit('input', {
        square: this.croppedImageSquare,
        horizontal: this.croppedImageHorizontal,
      });
    }
  }

  onImageInput(image: File) {
    this.loadedImage = image;
  }
}
